import React from 'react'
import "../assest/styles/upcomingStyle.scss"

const UpcomingPage = ({bannerImg, title}) => {
  return (
    <div className='upcoming-page'>
        <h1 className='mb-0 py-5'>Comming soon</h1>
    </div>
  )
}

export default UpcomingPage